import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeAbout from "../Home/HomeAbout/HomeAbout";
import HomeBrands from "../Home/HomeBrands/HomeBrands";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import HomeTwoAchieve from "../HomeTwo/HomeTwoAchievement/HomeTwoAchieve";
import HomeTwoFaq from "../HomeTwo/HomeTwoFaq/HomeTwoFaq";
import HomeTwoHeader from "../HomeTwo/HomeTwoHeader/HomeTwoHeader";
import HomeTwoFeaturesComplete from "../HomeTwo/HomeTwoFeatures/HomeTwoFeaturesComplete";
import Header1 from "../Home/HomeHeroArea/HomeHeroSlider";
import Header2 from "../HeaderStyleFour/HeaderStyleFour";
import Header3 from "../HeaderStyleSeven/HeaderStyleSeven";

const About = () => {
  return (
    <>
      <PageHelmet pageTitle="Sobre" />
      <HomeOneHeader />
      <CommonPageHeader
        title="Sobre nós"
        subtitle="Sobre"
        image="url(assets/img/page-title/page-title-1.jpg)"
      />
      <HomeAbout />
      <HomeBrands />
      <HomeTwoFeaturesComplete />
      <Header1 />
      <HomeTwoAchieve />

      <Footer />
    </>
  );
};

export default About;
