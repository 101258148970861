import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import Footer from "../../components/shared/Footer";
import BlogsArea from "./BlogsArea/BlogsArea";
import { useLocation, Link } from "react-router-dom";

const Blogs = (props) => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <PageHelmet pageTitle="Blog" />

      <HomeOneHeader />
      <CommonPageHeader
        title="Blog"
        subtitle="Artigos"
        image="url(assets/img/page-title/page-title-3.jpg)"
      />
      <BlogsArea />
      <Footer />
    </>
  );
};

export default Blogs;
