import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { Link } from "react-router-dom";

const HomeTwoSingleFeature = ({ icon, title, p, p1, p2, p3, im }) => {
  return (
    <>
      <div className="features__item features__item-2 white-bg fix mb-30">
        <div
          className="features__thumb-2"
          style={{
            filter: "grayscale(100%)",

            background: im,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="features__content-2">
          <div className="features__icon features__icon-2">
            <i> {icon} </i>
          </div>
          <h3>{title}</h3>
          <p style={{ textAlign: "justify" }}>{p}</p>
          <p style={{ textAlign: "justify" }}>{p1}</p>
          <p style={{ textAlign: "justify" }}>{p2}</p>
          <p style={{ textAlign: "justify" }}>{p3}</p>
        </div>
      </div>
    </>
  );
};

export default HomeTwoSingleFeature;
