import React from "react";
import Slider from "react-slick";
import VideoPopup from "../../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../../hooks/useGlobalContext";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import { AiOutlineMinus } from "react-icons/ai";

const HomeHeroArea = () => {
  const { setIsVideoOpen } = useGlobalContext();
  // slider data
  const homeSliderData = [
    {
      id: 1,
      bgImg: "home_slider_1",
    },
    {
      id: 2,
      bgImg: "home_slider_2",
    },
  ];
  // slick setting
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    infinite: true,
    dots: false,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="slider__area2">
        <Slider className="slider-active" {...settings}>
          {homeSliderData.map((slider, index) => {
            return (
              <div
                key={index}
                className={`single-slider slider__height d-flex align-items-center ${slider.bgImg}`}
              >
                <div className="container h1_slider_wrapper">
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-10 col-sm-10">
                      <div
                        className="slider__content"
                        style={{ marginTop: "20px" }}
                      >
                        <span>Abordagem Holística</span>
                        <h1>Positive Aging & Pro-Aging</h1>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6">
                      <h4 style={{ textAlign: "justify", color: "white" }}>
                        Para falar de qualquer tipo de tratamento é preciso
                        entender como ocorre o envelhecimento. Não se envelhece
                        repentinamente, este é um processo que inicia partir dos
                        vinte e cinco anos e perdura até ao fim da vida.
                      </h4>
                      <br></br>

                      <p style={{ textAlign: "justify", color: "white" }}>
                        À medida que envelhecemos existem fatores internos tais
                        como a reabsorção óssea que acaba por modificar o
                        tamanho e a forma das cavidades e alterar a posição das
                        estruturas que assentam sobre o esqueleto, músculos,
                        tecido adiposo e pele. A par da reabsorção óssea, também
                        há uma redução da massa muscular e tepidez dos
                        ligamentos que sustentam a face, culminando assim num
                        rosto com aspecto laxo e descaído. Não obstante, a
                        redução de colágeno e elastina, a presença de manchas
                        (provocadas pela exposição solar) bem como a redução da
                        concentração de água no organismo são também fatores que
                        dão à pele um aspecto envelhecido.
                        <br></br>Quanto aos fatores externos, o sol,
                        contaminação, stress, e o próprio passar do tempo, têm
                        uma relação direta com a perda de hidratação,
                        elasticidade e firmeza da pele.Embora envelhecer seja
                        inevitável, podemos desacelerar o processo: de forma
                        biológica, com hábitos benéficos para o nosso organismo
                        , com efeitos positivos na auto-estima e, acima de tudo,
                        com acréscimo de longevidade. Assim, entende-se que a
                        genética não determina tudo, nós também podemos escolher
                        como vamos envelhecer. É impossível falar destes
                        tratamentos sem pensar no rosto como um todo.
                      </p>
                    </div>
                    <div className="col-xl-6">
                      <p style={{ textAlign: "justify", color: "white" }}>
                        O envelhecimento cutâneo traduz-se, na maior parte das
                        vezes, num rosto apagado com aparência cansada e triste.
                        Neste processo, é importante perceber que são muitas as
                        zonas faciais afetadas e que revelam consequências nos
                        terços faciais - terço superior , médio e inferior da
                        face. Deste modo, ao tratar suavemente e em harmonia
                        todas as áreas podemos recuperar um rosto mais tenso e
                        luminoso de forma completamente natural. O tratamento de
                        rejuvenescimento facial FULL FACE consiste em tratar o
                        rosto com recurso a ácido hialurónico: de diferentes
                        densidades, com diferentes técnicas e nas diferentes
                        camadas da pele.
                        <br></br>O intuito da utilização deste produto visa em
                        recuperar a elasticidade da pele, atenuar as sombras e
                        restaurar a tensão das zonas mais flácida. Assim:
                      </p>
                      <div className="about">
                        <ul style={{ color: "white", textAlign: "justify" }}>
                          <li style={{ color: "white" }}>
                            <span>
                              <i>
                                {" "}
                                <AiOutlineMinus />{" "}
                              </i>{" "}
                              No terço superior o objetivo é refrescar o olhar
                              ao tratar o contorno dos olhos e a zona das
                              olheiras.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i>
                                {" "}
                                <AiOutlineMinus />{" "}
                              </i>
                              No terço médio a prioridade consiste em dar luz à
                              zona central do rosto: maças do rosto, sulco
                              nasogeniano e lábio superior.
                            </span>
                          </li>

                          <li>
                            <span>
                              <i>
                                {" "}
                                <AiOutlineMinus />{" "}
                              </i>
                              No terço inferior, a atenção é focada na definição
                              do perfil e contorno facial, bem como no
                              tratamento do lábio inferior, do sorriso triste,
                              queixo e arco mandibular em toda a sua dimensão.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <p style={{ textAlign: "justify", color: "white" }}>
                      Todos estes procedimentos são realizados com o intuito de
                      embelezar o rosto, de forma a criar harmonia sem alterar
                      os traços individuais do paciente. Na PROAGE trabalhamos
                      para atingir resultados naturais e imediatos (embora seja
                      necessário aguardar alguns dias de pós operatório para que
                      o resultado integre na sua plenitude e expoência). Os
                      tratamentos são feitos numa única sessão ou de forma
                      gradual (de acordo com o ritmo e vontade do paciente). São
                      sempre recomendadas consultas a cada seis meses para
                      avaliar o resultado e serem feitas as manutenções
                      necessárias à persistência do resultado no tempo.
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </section>
    </>
  );
};

export default HomeHeroArea;
