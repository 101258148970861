import React, { useRef } from "react";
import emailjs from "emailjs-com";

const ContactArea = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3dbw7wu",
        "template_48m6b4o",
        form.current,
        "9w6FUYsHMZpLqb9S8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    form.current.reset();
  };

  return (
    <>
      <section className="contact__area">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-xl-12">
              <div className="contact__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12015.403359052403!2d-8.6824733!3d41.159606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36af13c78cb0c141!2sProage%20Clinic%20by%20Joana%20Lino!5e0!3m2!1spt-PT!2spt!4v1660323646799!5m2!1spt-PT!2spt"
                  title="contact"
                ></iframe>
                <div
                  className="contact__wrapper d-md-flex justify-content-between wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="contact__info mr-100">
                    <h3>Entre em contacto!</h3>

                    <h4>Morada</h4>
                    <p>
                      Rua De Gondarém, 782, Fr. E, <br />
                      Foz do Douro, Porto
                    </p>

                    <h4>Telefone</h4>
                    <p>
                      <a href="tel:(351)-934-935-050">(+351) 934 935 050</a>
                    </p>

                    <h4>Email</h4>
                    <p>
                      <a href="mailto:proage@byjoanalino.com">
                        proage@byjoanalino.com
                      </a>
                    </p>
                  </div>
                  <div className="contact__form">
                    <form ref={form} onSubmit={sendEmail}>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        placeholder="Nome"
                      />
                      <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder="Email"
                      />
                      <input
                        required
                        type="text"
                        name="subject"
                        id="subject"
                        placeholder="Assunto"
                      />
                      <textarea
                        required
                        id="message"
                        name="message"
                        placeholder="Mensagem"
                      ></textarea>
                      <button type="submit" className="z-btn">
                        Enviar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
