import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { GiLoveInjection, GiComb } from "react-icons/gi";
import { FaTooth, FaPumpSoap, FaNutritionix } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeTwoSingleFeature from "../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature";

const HomeTwoFeatures = () => {
  return (
    <>
      <section className="features__area pt-115 pb-120">
        <div className="container">
          <div className="features__content-right">
            <div className="row">
              <div className="section__title section__title-h2 mb-25">
                <span>Uma equipa ampla de especialistas</span>
                <h2>Áreas de atuação</h2>
              </div>
              <div className="col">
                <HomeTwoSingleFeature
                  icon={<GiLoveInjection />}
                  title="Medicina Estética"
                  p1="A Medicina Estética é um conjunto de procedimentos estéticos e funcionais que, quando combinados, melhoram as proporções do rosto, transformam algumas características e tratam o envelhecimento da pele, caracterizado pela perda de elasticidade, volume e gordura.

                  Procura destacar a beleza natural de cada pessoa, realçando os seus melhores atributos e disfarçando os detalhes que mais incomodam."
                  p2="Com uma avaliação personalizada, o foco não está no tratamento de rugas e sulcos, mas sim em melhorar detalhes do rosto, definindo os ângulos do mesmo, equilíbrio e proporção facial, assim como a forma como a luz se reflete na face cria sombras, criando um rosto mais contornado.
                  
                  Podemos conseguir resultados excelentes com técnicas combinadas como preenchimento de acido hialurónico, toxina botulínica (botox), bioestimuladores de colagénio, peelings químicos, entre outros.
                  
                  A beleza depende de ângulos e proporções ideias.
                  "
                  p3="
                  Está indicada para homens e mulheres que procuram rejuvenescer com naturalidade, corrigir assimetrias, ou alterações funcionais e manter a sua beleza e saúde ao longo dos tempos.
                  
                  Potenciar a beleza de cada um que é única!"
                  im="url(assets/img/features/02/features-1.jpg)"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <HomeTwoSingleFeature
                  icon={<FaPumpSoap />}
                  title="Dermatologia"
                  p1="Ao Dermatologista é exigido profunda preparação e estudo, grande formação e competência.
                  Além das doenças próprias de qualquer órgão, a pele como estrutura de fronteira, sofre influência de doenças internas e agressões externas e ambientais. Assim, a patologia dermatológica é mais complexa do que qualquer outro órgão e as dermatoses são inumeráveis."
                  p2="Independentemente da gravidade da doença dermatológica, é muito importante consultar o médico especialista para evitar a automedicação, que além que não resolver o problema apresentado pelo paciente, pode piorar o quadro e dificultar o tratamento correto. Em alguns casos, como o cancro de pele, o diagnóstico precoce é essencial para um tratamento eficaz que devolva ao paciente a sua qualidade de vida. Se detetar algum sinal de um problema dermatológico, procure o seu médico. "
                  im="url(assets/img/features/02/derm.jpg)"
                />
                <HomeTwoSingleFeature
                  icon={<GiComb />}
                  title="Tratamentos Capilares"
                  p1="Tal como a pele, o cabelo não tem apenas uma necessidade. Na PROAGE, temos médicos especializados na área de Transplante Capilar e Medicina Estética, com vasta experiência em micro-cirurgia capilar e tratamentos medico-estéticos."
                  p2=" Venha conhecer os tratamentos que temos para oferecer para renovar a sua saudade capilar. "
                  im="url(assets/img/features/02/hair.jpg)"
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <HomeTwoSingleFeature
                  icon={<FaTooth />}
                  title="Medicina Dentária"
                  p1=" Medicina Dentária é a área da saúde que previne, diagnostica e trata as doenças que afectam os dentes, as gengivas e a mucosa oral. "
                  p2="É também esta área que faz a reabilitação da estética e função dentárias de forma que o paciente se sinta confortável (em todas as fases da sua vida) não só a comer e a falar mas também a sorrir.  "
                  p3="Dispomos de tratamentos cirúrgicos e de reabilitação, ortodônticos e endodônticos. Temos também uma área dedicada à medicina dentária generalista, incidindo na indispensável higiene oral e no diagnóstico, prevenção e terapeutica de doenças orais."
                  im="url(assets/img/features/02/dentist.jpg)"
                />
                <HomeTwoSingleFeature
                  icon={<FaNutritionix />}
                  title="Nutrição"
                  p1="A consulta de nutrição tem como objectivo acompanhar as diversas fases de vida e as suas necessidades. Assim sendo, estas podem ter diferentes motivos: consulta nutrição para perder peso e emagrecer, nutrição desportiva, nutrição para aumento de peso e anti-aging."
                  p2="Na PROAGE, realizamos todo o planeamento alimentar em situações específicas, de uma maneira personalizada e com toda a dedicação - quer através de métodos de dieta convencionais, quer através de dieta Pronokal."
                  im="url(assets/img/portfolio/port-12.jpg)"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFeatures;
