import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import useGlobalContext from "../../../hooks/useGlobalContext";

const HomeTwoHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { stickyMenu } = useGlobalContext();
  return (
    <>
      <header>
        <div className="header__area p-relative header__transparent">
          <div
            id="header__sticky"
            className={
              stickyMenu
                ? "sticky header__bottom header__bottom-2"
                : "header__bottom header__bottom-2"
            }
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                  <div className="logo">
                    <NavLink to="/">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/img/logo/logo-white.png"
                        }
                        alt="logo"
                        style={{ width: "150px", filter: "none" }}
                      />
                    </NavLink>
                  </div>
                  <div className="logo-gradient">
                    <NavLink to="/">
                      <img
                        src="assets/img/logo/logo-brown.png"
                        alt="logo"
                        style={{ width: "150px", filter: "none" }}
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                  <div className="header__bottom-right d-flex justify-content-end align-items-center">
                    <div className="main-menu menu_wrapper_one">
                      <nav id="mobile-menu">
                        <ul>
                          <li>
                            <NavLink to="/home">Clínica</NavLink>
                          </li>
                          <li>
                            <NavLink to="/sobre">Sobre</NavLink>
                          </li>
                          <li>
                            <NavLink to="/tratamentos">Tratamentos</NavLink>
                          </li>

                          <li>
                            <NavLink to="/casos">Casos Clínicos</NavLink>
                          </li>
                          <li>
                            <NavLink to="/blogs">Blog</NavLink>
                          </li>
                          <li>
                            <NavLink to="/contactos">Contactos</NavLink>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div
                      onClick={handleShow}
                      className="sidebar__menu d-lg-none"
                    >
                      <div className="sidebar-toggle-btn" id="sidebar-toggle">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeTwoHeader;
