import React from "react";
import { Link } from "react-router-dom";

const CommonCtaArea = () => {
  return (
    <>
      <section
        className="cta__area pt-190 pb-200"
        style={{
          background: `url(assets/img/cta/cta-bg.jpg)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container p-relative">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">
              <div className="cta__content text-center">
                <span>Venha conhecer a nossa Clínica</span>
                <h1>Agende uma consulta de avaliação!</h1>
                <div className="cta__btn">
                  <Link to="/sobre" className="z-btn z-btn-white mb-30">
                    Saiba Mais
                  </Link>
                  <Link
                    to="/contactos"
                    className="z-btn z-btn-transparent mb-30"
                  >
                    Contacte-nos
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonCtaArea;
