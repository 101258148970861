import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BiMap } from "react-icons/bi";
import { FaEnvelope, FaPhoneAlt, FaSearch } from "react-icons/fa";

const Sidebar = ({ show, handleClose }) => {
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton>
            <div className="logo">
              <a href="index.html">
                <img
                  src="assets/img/logo/logo-white.png"
                  alt="logo"
                  width="150px"
                />
              </a>
            </div>
          </Offcanvas.Header>

          <Offcanvas.Body>
            <section>
              <div className="p-0">
                <div className="sidebar__tab">
                  <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="menu-tab"
                        data-bs-toggle="tab"
                        href="#menu"
                        role="tab"
                        aria-controls="menu"
                        aria-selected="true"
                      >
                        menu
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="info-tab"
                        data-bs-toggle="tab"
                        href="#info"
                        role="tab"
                        aria-controls="info"
                        aria-selected="false"
                      >
                        info
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sidebar__content">
                  <div className="tab-content" id="sidebar-tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="menu"
                      role="tabpanel"
                      aria-labelledby="menu-tab"
                    >
                      <div className="side_navBar">
                        <div className="about iconAdd border-0">
                          <NavLink to="/home">Clínica </NavLink>
                        </div>
                        <div className="about iconAdd border-0">
                          <NavLink to="/sobre">Sobre </NavLink>
                        </div>
                        <div className="about iconAdd border-0">
                          <NavLink to="/tratamentos">Tratamentos </NavLink>
                        </div>

                        <div className="about iconAdd border-0">
                          <NavLink to="/casos">Casos Clínicos </NavLink>
                        </div>
                        <div className="about iconAdd border-0">
                          <NavLink to="/blogs">Blog </NavLink>
                        </div>
                        <div className="about iconAdd border-0">
                          <NavLink to="/contactos">Contactos </NavLink>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="info"
                      role="tabpanel"
                      aria-labelledby="info-tab"
                    >
                      <div className="sidebar__info">
                        <p>
                          A positive view of aging.
                          <br></br>
                          Aesthetic Medicine & Positive Aging.
                        </p>

                        <div className="sidebar__contact mt-30">
                          <ul>
                            <li>
                              <div className="icon">
                                <i>
                                  {" "}
                                  <BiMap />{" "}
                                </i>
                              </div>
                              <div className="text">
                                <span>
                                  Rua De Gondarém, 782, Fr. E, Foz do Douro,
                                  Porto
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i>
                                  {" "}
                                  <FaEnvelope />{" "}
                                </i>
                              </div>
                              <div className="text ">
                                <span>
                                  <a href="mailto:proage@byjoanalino.com">
                                    proage@byjoanalino.com
                                  </a>
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="icon">
                                <i>
                                  <FaPhoneAlt />{" "}
                                </i>
                              </div>
                              <div className="text">
                                <span>
                                  <a href="tel:(+351) 934 935 050">
                                    (+351) 934 935 050
                                  </a>
                                </span>
                              </div>
                            </li>
                            <li>
                              <div style={{ paddingTop: "20px" }}>
                                <span>
                                  <a
                                    href="https://api.whatsapp.com/send?phone=351934935050&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta%21"
                                    className="z-btn z-btn-white"
                                  >
                                    WhatsApp
                                  </a>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
