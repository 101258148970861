import React from "react";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { FiPlay } from "react-icons/fi";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { Link } from "react-router-dom";
import BlogSingleItem from "../../../components/BlogSingleItem/BlogSingleItem";
import VideoPopup from "../../../components/VideoPopup/VideoPopup";
import useGlobalContext from "../../../hooks/useGlobalContext";
import BlogLeftSideBar from "./BlogLeftSideBar";

const BlogsArea = () => {
  return (
    <>
      <section className="blog__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="blog__wrapper mr-50">
                <BlogSingleItem
                  image="2"
                  user_image="1"
                  date="21 de Janeiro de 2022"
                  name="Joana Lino e Andreia Lourador"
                  title="#RevengeMe – A fusão da Medicina Estética e da Saúde Mental"
                  subtitle="#RevengeMe nasceu da vontade de aliar a Medicina Estética e a Saúde
                  Mental. O objetivo é ajudar a mulher a embelezar-se dentro e fora,
                  garantindo um acompanhamento nestas duas importantes áreas da saúde
                  ao longo de todo o percurso de empoderamento."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogsArea;
