import React from "react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer__area grey-bg pt-100">
          <div className="footer__top pb-45">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title mb-30">
                      <div className="logo">
                        <Link to="/">
                          <img
                            style={{ width: "100px", filter: "none" }}
                            src="assets/img/logo/logo-gradient.png"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="footer__widget-content">
                      <p className="mb-30" style={{ fontSize: "12px" }}>
                        PROAGE Clinic by Joana Lino | Aesthetic Medicine{" "}
                        <br></br>
                        Copyright © 2022 All Rights Reserved.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Contactos</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <h5>Telefone</h5>
                            <p>
                              <a href="tel:(351)-934-935-050">
                                <small>(+351) 934 935 050</small>
                              </a>
                            </p>
                          </li>
                          <li>
                            <h5>Email</h5>
                            <p>
                              <a href="mailto:proage@byjoanalino.com">
                                <small>proage@byjoanalino.com</small>
                              </a>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="footer__widget mb-45">
                    <div className="footer__widget-title">
                      <h4>Consultório</h4>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          <li>
                            <h5>Morada</h5>
                            <p>
                              <small>
                                Rua De Gondarém, 782, Fr. E, Foz do Douro, Porto
                              </small>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="footer__social theme-social mb-30"
                  style={{ textAlign: "center" }}
                >
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/proageclinicbyjoanalino/">
                        <i>
                          <FaFacebookF />
                        </i>
                        <i>
                          <FaFacebookF />
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/_proageclinic_/">
                        <i>
                          <FaInstagram />{" "}
                        </i>
                        <i>
                          <FaInstagram />{" "}
                        </i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/proage-clinic-by-joana-lino-380269171/">
                        <i>
                          <FaLinkedin />{" "}
                        </i>
                        <i>
                          <FaLinkedin />{" "}
                        </i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
