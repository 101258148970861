import React from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { GiLoveInjection, GiComb } from "react-icons/gi";
import { FaTooth, FaPumpSoap, FaNutritionix } from "react-icons/fa";
import { Link } from "react-router-dom";
import HomeTwoSingleFeature from "../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature";

const HomeTwoFeatures = () => {
  return (
    <>
      <section className="features__area pt-115 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="features__content-left">
                <div className="section__title section__title-h2 mb-25">
                  <span>Uma equipa ampla de especialistas</span>
                  <h2>
                    Áreas <br /> de atuação
                  </h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                  A PROAGE Clinic conta com a partilha de conhecimento de
                  profissionais de múltiplas especialidades. Aliando o sentido
                  crítico à vontade de aprender mais e à dedicação para colocar
                  em prática os nossos conhecimentos adquiridos, esta combinação
                  de diferentes áreas de atuação permite-nos criar as condições
                  ideais para atingirmos os resultados pretendidos em cada um
                  dos nossos pacientes.
                </p>
                <Link
                  to="/tratamentos"
                  className="z-btn"
                  style={{ marginBottom: "20px" }}
                >
                  Tratamentos
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link>
              </div>
            </div>
            <div className="col-xl-6 offset-xl-1 col-lg-6">
              <div className="features__content-right">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 aesthetic">
                    <HomeTwoSingleFeature
                      icon={<GiLoveInjection />}
                      title="Medicina Estética"
                      p="Um conceito que procura destacar a beleza natural de cada pessoa, realçando as suas melhores características e disfarçando os detalhes que mais incomodam."
                      im="url(assets/img/features/02/features-1.jpg)"
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaPumpSoap />}
                      title="Dermatologia"
                      p="A Dermatologia não está só ligada a problemas de pele, mas também está orientada para o acompanhamento do precoce de envelhecimento. "
                      im="url(assets/img/features/02/derm.jpg)"
                    />
                    <HomeTwoSingleFeature
                      icon={<FaNutritionix />}
                      title="Nutrição"
                      p="A consulta de nutrição pode ter o objetivo de acompanhar perda de peso e emagrecimento, nutrição desportiva, nutrição para aumento de peso e até anti-aging. "
                      im="url(assets/img/portfolio/port-12.jpg)"
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                    <HomeTwoSingleFeature
                      icon={<FaTooth />}
                      title="Medicina Dentária"
                      p="A Medicina Dentária é a área da saúde que previne, diagnostica e trata as doenças que afectam os dentes, as gengivas e a mucosa oral. "
                      im="url(assets/img/features/02/dentist.jpg)"
                    />

                    <HomeTwoSingleFeature
                      icon={<GiComb />}
                      title="Tratamentos Capilares"
                      p="Tal como a pele, o cabelo não tem apenas uma necessidade. Venha conhecer os tratamentos que temos para oferecer para renovar a sua saudade capilar. "
                      im="url(assets/img/features/02/hair.jpg)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFeatures;
