import React from "react";

const HomeTwoFaq = () => {
  return (
    <>
      <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
        <div
          className="faq__thumb"
          style={{
            filter: "grayscale(100%)",
            background: `url(assets/img/faq/faq-1.jpg)`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
              <div className="faq__wrapper">
                <div
                  className="section__title section__title-3 mb-25 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <span>Simplificar e Normalizar!</span>
                  <h2>
                    Vamos desconstruir <br /> a medicina estética?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    Acreditamos na importância de uma linguagem simples e
                    próxima ao paciente. É importante entender os procedimentos
                    possíveis sem preconceitos e conseguir descomplicar este
                    “monstro” que tantos estigmas cria à sua volta, ainda nos
                    dias-de-hoje.
                  </p>
                </div>

                <div className="accordion" id="accordionExample">
                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Os procedimentos realizados são definitivos?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="card-body accordion-body"
                        style={{ textAlign: "justify" }}
                      >
                        Os procedimentos não são definitivos: o ácido
                        hialurónico, por exemplo, substância biocompatível usada
                        em diversos procedimentos estéticos, tem tempo de
                        duração estimado entre seis meses a um ano e meio,
                        dependendo da região aplicada e até do estilo de vida do
                        paciente. Com o tempo, vai sendo absorvido naturalmente
                        pelo corpo. Sendo necessário voltar ao consultório para
                        realizar manutenção.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingTwo">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Qual o melhor, botox ou ácido hialurónico?
                        </button>
                      </h5>
                    </div>

                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="card-body accordion-body"
                        style={{ textAlign: "justify" }}
                      >
                        Não existe melhor ou pior quando se trata de escolher
                        botox ou ácido hialurónico. Ambos são usados para
                        amenizar as marcas de envelhecimento, e em ambos são
                        utilizadas agulhas ou cânulas para a aplicação
                        subcutânea. Os procedimentos têm efeitos temporários e
                        necessitam manutenção de resultados.
                      </div>
                    </div>
                  </div>

                  <div className="card accordion-item">
                    <div className="accordion-header" id="headingThree">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Um rosto com mais preenchimento <br></br>aparenta ser
                          mais rejuvescido?{" "}
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="card-body accordion-body"
                        style={{ textAlign: "justify" }}
                      >
                        <strong>NEM SEMPRE!</strong> Ser PROAGE é também saber
                        dizer não a procedimentos que se realizados antes do
                        tempo podem causar um impacto brutal no processo de
                        envelhecimento. Demasiado contorno para obter uma
                        aparência demasiado óbvia de magreza durante os nossos
                        20-30 anos irá impactar o nosso rosto de 50 anos pois a
                        estrutura criada será de muito maior manutenção e a
                        aparência natural que sempre procuramos será mais
                        difícil de atingir!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoFaq;
