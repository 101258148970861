import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Carousel from "react-bootstrap/Carousel";
import { AiOutlineSmallDash } from "react-icons/ai";

const HomeCapabilities = () => {
  return (
    <>
      <section className="capabilities__area p-relative  pt-180 pb-155 fix">
        <div className="container">
          <div className="row">
            <div className="faq__wrapper">
              <div
                className="section__title section__title-3 mb-25 wow fadeInUp"
                data-wow-delay=".2s"
              >
                <span>O antes e depois dos nossos pacientes</span>
                <h2>Casos clínicos selecionados</h2>
                <p style={{ textAlign: "justify" }}>
                  A medicina estética e de rejuvenescimento busca proporcionar o
                  equilíbrio e harmonia facial através de alguns procedimentos
                  que corrigem assimetrias, melhoram as proporções faciais e
                  garantem um aspeto rejuvenescido da pele. É possível realizar
                  diferentes procedimentos estéticos e minimamente invasivos,
                  que quando combinados, deixam o rosto mais simétrico,
                  harmonioso, rejuvenescido e saudável. Além de cuidar do
                  bem-estar e saúde, melhora a autoestima dos pacientes.
                </p>
              </div>

              <div className="accordion" id="accordionExample">
                <div className="card accordion-item">
                  <div className="accordion-header" id="headingOne">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Bichectomia <br></br>
                        <small style={{ fontSize: "12px" }}>
                          {" "}
                          E pontos de sustentação com ácido hialurónico
                        </small>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="capabilities__card-body accordion-body">
                      <Carousel fade interval="3000">
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/bis1.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/bis2.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="capabilities__row row">
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Antes</h5>
                      </div>
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Depois</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="accordion-header" id="headingFour">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Contorno mandibular
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="capabilities__card-body accordion-body"
                      style={{ textAlign: "justify" }}
                    >
                      <Carousel fade interval="3000">
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/mand1.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/mand2.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="capabilities__row row">
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Antes</h5>
                      </div>
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Depois</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card accordion-item">
                  <div className="accordion-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Preenchimento labial<br></br>
                      </button>
                    </h5>
                  </div>

                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="capabilities__card-body accordion-body"
                      style={{ textAlign: "justify" }}
                    >
                      <Carousel fade interval="3000">
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/lab1.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/lab2.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="capabilities__row row">
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Antes</h5>
                      </div>
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Depois</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Full face <br></br>
                        <small style={{ fontSize: "12px" }}>
                          Ácido hialurónico, botox, bioestimuladores
                        </small>
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="capabilities__card-body accordion-body"
                      style={{ textAlign: "justify" }}
                    >
                      <Carousel fade interval="3000">
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/full1.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/full2.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="capabilities__row row">
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Antes</h5>
                      </div>
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Depois</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card accordion-item">
                  <div className="accordion-header" id="headingThree">
                    <h5 className="mb-0">
                      <button
                        className="btn btn-link collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        Sorriso gengival
                      </button>
                    </h5>
                  </div>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div
                      className="capabilities__card-body accordion-body"
                      style={{ textAlign: "justify" }}
                    >
                      <Carousel fade interval="3000">
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/harm1.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src="assets/img/capabilities/harm2.jpg"
                            alt="First slide"
                            style={{ borderRadius: "10px" }}
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                    <div className="capabilities__row row">
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Antes</h5>
                      </div>
                      <div className="col" style={{ textAlign: "center" }}>
                        <h5>Depois</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeCapabilities;
