import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const HomeTwoAchieve = () => {
  return (
    <>
      <section
        className="achievement__area pt-135 pb-40"
        style={{ marginBottom: "80px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="achievement__content">
                <div className="section__title section__title-3 mb-20">
                  <span>O Espaço</span>
                  <h2>
                    A mesma casa <br></br>uma nova identidade
                  </h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Estamos situados no número 782 da Rua de Gondarém, no Porto,
                  desde 2019. Agora com uma nova identidade, a PROAGE Clinic é o
                  lugar onde pode encontrar diariamente a Drª Joana Lino e uma
                  equipa multidisciplinar de mais de 10 profissionais das mais
                  diversas áreas – desde medicina dentária individualizada a
                  transplante capilar, passando por dermatologia e nutrição. Com
                  uma avaliação personalizada de cada pessoa, o foco está em
                  definir ângulos, equilíbrio e proporção facial, assim como
                  jogar com o binómio luz/sombra, criando um rosto mais
                  contornado.
                </p>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="row">
                <div className="col">
                  <div className="achievement__thumb m-img pl-10 text-right">
                    <Carousel fade interval="3000">
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/IMG_2301.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/IMG_2388.jpg"
                          alt="Second slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/IMG_2410.jpg"
                          alt="Third slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/consultorio1.jpg"
                          alt="Fourth slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>

                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/consultorio2.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/consultorio2-1.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/consultorio3.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/consultorio3-1.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/estudio1.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/estudio2.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/IMG_2296.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/meso.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src="assets/img/project/quadro.jpg"
                          alt="First slide"
                          style={{ borderRadius: "10px" }}
                        />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAchieve;
