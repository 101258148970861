import React from "react";
import { FaFacebookF, FaTwitter, FaVimeoV } from "react-icons/fa";
import BlogLeftSideBar from "../../Blogs/BlogsArea/BlogLeftSideBar";
import { BiReply } from "react-icons/bi";
import { useLocation, Link } from "react-router-dom";

const BlogDetailsArea = (props) => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <section className="blog__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <h3>
              #RevengeMe nasceu da vontade de aliar a Medicina Estética e a
              Saúde Mental. O objetivo é ajudar a mulher a embelezar-se dentro e
              fora, garantindo um acompanhamento nestas duas importantes áreas
              da saúde ao longo de todo o percurso de empoderamento.{" "}
            </h3>
            <div className="col-xl-8 col-lg-8">
              <div className="blog__details-wrapper mr-50">
                <div className="blog__text mb-45">
                  <br></br>

                  <h4>
                    A fusão da Medicina Estética e Saúde Mental juntas num
                    #RevengeMe
                  </h4>
                  <p style={{ textAlign: "justify" }}>
                    Saúde Mental e Medicina Estética juntas, porquê? Porque não
                    há beleza quando um corpo se dissocia de uma mente. Porque
                    não existe beleza no vácuo. Não se trata de ser mais magro
                    ou parecer mais jovem com o objetivo de ser mais respeitada
                    e admirada pelos outros. É sobre amarmo-nos e sermos felizes
                    só connosco. É sobre entrar num espaço com a confiança de
                    quem ama o seu corpo, mas sobretudo de quem ama quem é.
                    Depois de um #RevengeMe o que acontece é uma mudança de
                    mindset. Como? Através de uma abordagem integral, onde
                    embelezamos não só o corpo, mas também a mente.{" "}
                  </p>
                  <h4 style={{ textAlign: "justify" }}>
                    O “Eu” é um todo integral. De dentro para fora. De fora para
                    dentro.
                  </h4>
                  <p style={{ textAlign: "justify" }}>
                    E queremos que ecoe para sempre. O conceito nasceu de uma
                    conversa com Joana Lino, médica especialista em harmonização
                    facial, e pretende ser uma lufada de ar fresco ao já
                    conhecido Revenge Body, inúmeras vezes adotado pelas
                    mulheres, após um término de uma relação. Este é um processo
                    ao qual temos vindo a assistir inclusive em diversas figuras
                    públicas. Quem nunca perdeu 20kg após uma separação? Ou
                    mudou o seu corte de cabelo? Ou inclusive mudou o seu corpo,
                    após passar horas no ginásio? Assistimos a este fenómeno a
                    todo o momento. Mudanças rápidas e drásticas após uma perda
                    (amorosa). Mas a maioria de nós segue por atalhos que nos
                    levam a um destino rápido, mas não permanente. Não podemos
                    cuidar de nós por atalhos.{" "}
                  </p>
                  <p style={{ textAlign: "justify" }}>
                    E, por isso, nasceu o #RevengeMe, que deixa totalmente de
                    lado a fragmentação do “eu” e a ideia de que a nossa melhor
                    versão é desencadeada por (e fruto de) um momento de dor. Em
                    vez disso, encara esta “vingança” como algo transversal que
                    deve englobar não só a parte física do “eu”, mas também
                    psicológica. O objetivo final é uma manutenção contínua e
                    estruturada da nossa individualidade, de tal forma que não
                    precisaremos de nenhum fator externo para sermos exatamente
                    aquilo que quisermos ser.
                  </p>
                  <h4>Investir no “Eu” não é um atalho, é um percurso.</h4>
                  <p style={{ textAlign: "justify" }}>
                    Investir no nosso “Eu” não pode ser um objetivo somente para
                    superar uma perda ou rutura de uma relação. Tem que ser o
                    percurso para a vida toda.
                    <br></br>
                    Quando estamos numa relação amorosa com alguém idealmente
                    deveria existir um eu, um tu e um nós. E o que acontece
                    muitas vezes é que passa a existir apenas um nós. O “eu”
                    perde-se, anula-se. E se esta relação termina, saímos dela
                    sem nada. Não sabemos quem somos, o que é que gostamos, tudo
                    o que nos carateriza enquanto seres individuais.
                    <br></br>O #RevengeMe funciona para nos mostrar que podemos
                    ser completamente autónomas e deixar de atribuir um peso
                    sobre-humano às coisas e pessoas que temos na nossa vida.
                    Quando compreendemos que estas são mutáveis e impermanentes,
                    contrariamente ao nosso “eu” – que nos acompanha para sempre
                    -, estamos perante uma verdadeira vingança no sentido de
                    empoderamento individual.
                    <br></br>
                    Um “eu” tem que ser construído, consolidado e mantido. A
                    manutenção da nossa individualidade deve ser um trabalho
                    constante e o #RevengeMe pretende ser um acordar para essa
                    realidade, um renascer com a segurança que nos traz o
                    autoconhecimento e a autoconfiança. E esta mutação pode ser
                    despoletada por um momento de dor, mas não necessariamente.
                    Pode e deve vir também de um lugar positivo. O desejo de se
                    sentir melhor. De ser melhor. Para si e consigo.
                  </p>
                  <div className="blog__details-thumb w-img mb-45">
                    <img src="assets/img/blog/details/b-d-1.jpg" alt="" />
                  </div>
                  <h4>Como o #RevengeMe pode ajudar a mulher?</h4>
                  <p style={{ textAlign: "justify" }}>
                    O conceito do #RevengeMe desenvolve-se em parceria, como uma
                    equipa em que há várias frentes a serem trabalhadas e que
                    englobam o aspeto físico e a vertente de saúde psicológica.
                    Estamos focadas na mulher como um todo, onde o “eu” é
                    celebrado e não esquecido, ou deixado para segundo plano.
                    <br></br>
                    Quando elevamos a nossa vibração atraímos experiências
                    melhores, com pessoas que funcionam na mesma frequência que
                    nós. Enquanto não aprendermos a amar-nos, a valorizar as
                    nossas qualidades, a responder às nossas próprias
                    necessidades, a dar força àquilo que queremos e aos nossos
                    sonhos estaremos a atrair pessoas que nos vão ensinar tudo
                    isso e, por vezes, de uma forma dolorosa.Todos estes
                    ensinamentos não precisam de vir da dor. Esta
                    consciencialização pode, sim, partir de nós e ser
                    espontânea. Requer esforço? Sim. Requer meditação, prática,
                    foco, autoanálise, dar um novo significado ao nosso passado
                    e mudança de crenças. Mas o #RevengeMe pretende que este
                    seja um processo prazeroso, pois cada ação terá uma reação
                    palpável nas nossas vidas. Façamos de nós mesmos a nossa
                    maior meta, o nosso maior compromisso e nunca em nenhum
                    momento e por nenhum motivo nos esqueçamos desse
                    compromisso.
                  </p>
                  <h3 style={{ textAlign: "justify" }}>
                    Quando paramos de procurar por alguma coisa ou por alguém,
                    encontramo-nos e aí não vamos precisar de mais nada.{" "}
                  </h3>
                  <p style={{ textAlign: "justify" }}>
                    O momento de adotar um estilo de vida baseado no
                    amor-próprio e na ideia de que devemos trabalhar na nossa
                    melhor versão – física e mentalmente – é agora. Junte-se a
                    nós neste #RevengeMe.
                  </p>
                  <span>
                    Artigo publicado em{" "}
                    <a
                      style={{ textDecorationLine: "underline" }}
                      href="https://www.simplyflow.pt/revengeme-a-fusao-da-medicina-estetica-e-da-saude-mental/"
                    >
                      <strong>Simply Flow by Fátima Lopes.</strong>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <BlogLeftSideBar />
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailsArea;
