import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import PageHelmet from "../../components/shared/PageHelmet";
import Footer from "../../components/shared/Footer";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import BlogDetailsArea from "./BlogDetailsArea/BlogDetailsArea";
import { useLocation, Link } from "react-router-dom";

const BlogDetails = (props) => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <PageHelmet pageTitle="Blog" />

      <HomeOneHeader />
      <CommonPageHeader
        subtitle="Artigo"
        title="#RevengeMe: A fusão da Medicina Estética e da Saúde Mental"
      />
      <BlogDetailsArea />
      <Footer />
    </>
  );
};

export default BlogDetails;
