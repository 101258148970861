import React from "react";
import { Link } from "react-router-dom";

const SingleExpartContent = ({ image }) => {
  return (
    <>
      <div className="expart__tab-content white-bg">
        <div className="row">
          <div className="col-xl-6 col-lg-6 offset-lg-6">
            <div
              className="expart__thumb"
              style={{
                filter: "grayscale(100%)",
                background: `url(assets/img/expart/expart-${image}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
          <div className="col-xl-6 col-lg-6 offset-lg-6">
            <div className="expart__content">
              <h3>
                A arte de potenciar
                <br /> a beleza de cada um
              </h3>
              <p>
                É sempre possível manter a beleza natural e a delicadeza do
                rosto. Pequenos detalhes que fazem toda a diferença exterior e
                interiormente.
              </p>
              <Link to="/casos" className="z-btn">
                Casos Clínicos
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleExpartContent;
