import React from "react";
import { useLocation } from "react-router-dom";
import { GiSandsOfTime } from "react-icons/gi";
import { Link } from "react-router-dom";
import { CgArrowLongLeft } from "react-icons/cg";

import { GiFairyWand } from "react-icons/gi";
import { GiSyringe } from "react-icons/gi";

const PortfolioDetailsArea = (props) => {
  const location = useLocation();
  const state = location.state;

  return (
    <>
      <section className="portfolio__details pt-120 pb-130">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="portfolio__img-wrapper">
                <div className="portfolio__img mb-30 w-img">
                  <img src={state.img} style={{ border: "10px" }} alt="" />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-6 offset-xl-1">
              {state && state.anestesia !== "none" && (
                <div className="portfolio__details-content mt-10">
                  <div className="portfolio__meta mb-5">
                    <h4>{state.subtitle}</h4>
                  </div>
                  <h1>{state.title}</h1>
                  <div className="portfolio__info mb-25">
                    <div className="row">
                      <div className="col-2">
                        <GiSyringe style={{ fontSize: "300%" }} />
                      </div>
                      <div className="col">
                        <h3>Qual é o tipo de anestesia utilizado?</h3>
                        <p>{state.anestesia}</p>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio__info mb-25">
                    <div className="row">
                      <div className="col-2">
                        <GiFairyWand style={{ fontSize: "300%" }} />
                      </div>
                      <div className="col">
                        <h3>
                          O resultado final é visível passado quanto tempo?
                        </h3>
                        <p style={{ textAlign: "justify" }}>
                          {state.resultado}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio__info mb-25">
                    <div className="row">
                      <div className="col-2">
                        <GiSandsOfTime style={{ fontSize: "300%" }} />
                      </div>
                      <div className="col">
                        <h3>Qual é a durabilidade do procedimento?</h3>
                        <p style={{ textAlign: "justify" }}>
                          {state.durabilidade}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio__overview mt-40">
                    <h2>O Procedimento</h2>
                    <p style={{ textAlign: "justify" }}>
                      {state.description} <br></br>
                      {state.descr}
                    </p>
                  </div>
                </div>
              )}
              {state && state.anestesia === "none" && (
                <div className="portfolio__details-content mt-10">
                  <div className="portfolio__meta mb-5">
                    <h4>{state.subtitle}</h4>
                  </div>
                  <h1>{state.title}</h1>

                  <div className="portfolio__overview mt-40">
                    <h2>O Procedimento</h2>
                    <p style={{ textAlign: "justify" }}>
                      {state.description}
                      <br></br>
                      {state.descr}
                    </p>
                  </div>
                </div>
              )}
              <div className="cta__btn">
                <br></br>
                <Link
                  to="/tratamentos"
                  className="z-btn z-btn-white mb-30"
                  style={{ borderColor: "#492b17 " }}
                >
                  <i>
                    <CgArrowLongLeft style={{ marginRight: "20px" }} />
                  </i>
                  Voltar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PortfolioDetailsArea;
