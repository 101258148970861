import React from "react";
import SIngleContactInfo from "../../../components/SIngleContactInfo/SIngleContactInfo";

const ContactInfoArea = () => {
  return (
    <>
      <section className="contact__help p-relative pt-115 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 offset-xl-1">
              <div className="section__title section__title-3 mb-55">
                <span>Contactos</span>
                <h2>Help Center</h2>
              </div>
            </div>
          </div>
          <div className="row p-relative">
            <SIngleContactInfo
              image="headset"
              title="WhatsApp"
              btn_text="Enviar Mensagem"
              contacto="https://api.whatsapp.com/send?phone=351934935050&amp;text=Ol%C3%A1%2C+gostaria+de+marcar+uma+consulta%21"
              texto="Temos uma equipa sempre pronta para qualquer esclarecimento. Tentamos responder com a maior
            brevidade possível!"
            />
            <SIngleContactInfo
              image="msg"
              title="Instagram"
              btn_text="Seguir"
              contacto="https://www.instagram.com/_proageclinic_/"
              texto="Sabia que pode saber mais acerca de procedimentos e até realizar marcações por mensagem direta nas nossas redes sociais?"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactInfoArea;
