import React from "react";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeTwoAchievement from "./HomeTwoAchievement/HomeTwoAchievement";
import HomeTwoAchieve from "./HomeTwoAchievement/HomeTwoAchieve";
import HomeTwoBlogs from "./HomeTwoBlogs/HomeTwoBlogs";
import HomeTwoCaseArea from "./HomeTwoCaseArea/HomeTwoCaseArea";
import HomeTwoCounter from "./HomeTwoCounter/HomeTwoCounter";
import HomeTwoExpertArea from "./HomeTwoExpertArea/HomeTwoExpertArea";
import HomeTwoFaq from "./HomeTwoFaq/HomeTwoFaq";
import HomeTwoFeatures from "./HomeTwoFeatures/HomeTwoFeatures";
import HomeTwoFooter from "../../components/shared/Footer";
import HomeTwoHeader from "./HomeTwoHeader/HomeTwoHeader";
import HomeTwoHeroSlider from "./HomeTwoHeroSlider/HomeTwoHeroSlider";
import HomeTwoTestimonial from "./HomeTwoTestimonial/HomeTwoTestimonial";

const HomeTwo = () => {
  return (
    <>
      <PageHelmet pageTitle=" Clinic by Joana Lino" />
      <HomeTwoHeader />
      <HomeTwoHeroSlider />
      <HomeTwoAchievement />
      <HomeTwoFeatures />
      <HomeTwoAchieve />
      <HomeTwoFaq />
      <HomeTwoExpertArea />
      <CommonCtaArea />
      <HomeTwoFooter />
    </>
  );
};

export default HomeTwo;
