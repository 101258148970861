const portfolioData = [
  {
    id: 1,
    img: "assets/img/portfolio/port-1.jpg",
    title: "Ácido hialurónico",
    subtitle: "Medicina Estética",
    category: "Medicina Estética",
    link: "acido-hialuronico",
    anestesia: "Tópica, 30 minutos antes da consulta",
    img1: "assets/img/portfolio/details/port-1.jpg",
    resultado:
      "O processo inflamatório decorre nos 15 dias após o procedimento e antes desse período não é possível avaliar resultados. Apenas a partir do primeiro mês é possível observar os efeitos mais próximo do resultado final.",
    durabilidade: "9-12 meses em média",
    description:
      "O preenchimento Facial contribui para resgatar a forma e o contorno do rosto, proporcionando equilíbrio e beleza de forma absolutamente natural. Muitas vezes pacientes associam preenchimentos a ficar com a cara 'mais gorda', o que é absolutamente MITO.",
    descr:
      "O preenchimento facial bem realizado e seguindo regras de técnica, anatomia e bom manuseio de produtos atribui um contorno muito mais definido e harmonioso ao rosto. Este procedimento tem como função prepor o volume em partes estratégicas do rosto ou ainda para disfarçar sulcos profundos, chamadas rugas estáticas.",
  },
  {
    id: 2,
    img: "assets/img/portfolio/port-2.jpg",
    title: "Proteína botulinica",
    subtitle: "Medicina Estética",
    category: "Medicina Estética",
    link: "proteina-botulinica",
    img1: "assets/img/portfolio/details/port-2.jpg",
    anestesia: "Tópica, 30 minutos antes da consulta",
    resultado:
      "O processo inflamatório decorre nos 15 dias após o procedimento e antes desse período não é possível avaliar resultados. Apenas a partir do primeiro mês é possível observar os efeitos mais próximo do resultado final.",
    durabilidade: "3-4 meses em média",
    description:
      "O “Botox” não apaga só as rugas, também as previne. Ao contrário do ácido Hialurónico, o Botox não é um voluminador. Este provoca o relaxamento dos músculos e, por esse facto, melhora as rugas de expressão. É um excelente produto, seguro e eficaz.",
    descr:
      "O efeito mantém-se durante 3 a 6 meses. Há diversas zonas de aplicação mediante o músculo que pretendemos relaxar. As suas aplicações mais conhecidas: sorriso gengival, bruxismo, sorriso triste (ruga de marionete), pescoço, zona superior do rosto (testa, cara de ma e rugas olhos), elevação da sobrancelha, entre outras.",
  },
  {
    id: 3,
    img: "assets/img/portfolio/port-3.jpg",
    title: "Bioestimuladores de colagénio",
    subtitle: "Medicina Estética",
    category: "Medicina Estética",
    link: "bioestimuladores-de-colagenio",
    img1: "assets/img/portfolio/details/port-3.jpg",

    anestesia: "Tópica, 30 minutos antes da consulta",
    resultado:
      "O processo inflamatório decorre nos 15 dias após o procedimento e antes desse período não é possível avaliar resultados. Apenas a partir do primeiro mês é possível observar os efeitos mais próximo do resultado final.",
    durabilidade: "9-12 meses em média",
    description:
      "Os bios estimuladores têm a função de acelerar a produção de colagénio pelo nosso corpo. Afinal de contas, com a idade a produção de colagénio no nosso corpo diminui. O colagénio é essencial para a estrutura, integridade e saúde dos ossos, articulações, pele, cabelos e sustentação dos órgãos e representa cerca de 25% de toda a proteína corporal. Ao mesmo tempo, a sua perda tem como consequência a diminuição da hidratação e elasticidade da pele, levando ao aumento e aparecimento de rugas.",
    descr:
      "Definitivamente, os bio estimuladores são uma grande opção para repor colagénio e são cada vez mais um recurso utilizado na clinica.",
  },
  {
    id: 4,
    img: "assets/img/portfolio/port-4.jpg",
    title: "Mesoterapia",
    subtitle: "Medicina Estética",
    category: "Medicina Estética",
    link: "mesoterapia",
    img1: "assets/img/portfolio/details/port-4.jpg",

    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Há vários métodos para aumentar a síntese de colágeno na derme, o que aumenta sua espessura e melhora a qualidade. Isso proporciona uma pele com textura e brilho, livre de mancha. Existem vários métodos para aumentar a síntese de colagénio na derme, o que aumenta a sua espessura e melhora a qualidade. Isso proporciona uma pele com textura e brilho, livre de manchas.",
    descr:
      " Todos os tratamentos que melhoram a qualidade da pele devem ser individualizados de acordo com a indicação de cada caso. Há provas científicas de que a associação de técnicas é necessária para se obter um resultado eficaz. Portanto, normalmente, os procedimentos somam-se para serem obtidos melhores resultados. Além disso, aquilo que fazemos no consultório é apenas uma parte, a rotina adequada e disciplinada em casa de skincare do paciente é fundamental.",
  },
  {
    id: 5,
    img: "assets/img/portfolio/port-8.jpg",
    title: "Medicina dentária generalista",
    subtitle: "Medicina Dentária",
    category: "Medicina Dentária",
    link: "medicina-dentaria-generalista",
    img1: "assets/img/portfolio/details/port-8.jpg",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Tudo começa com a higiene oral. Inclui o diagnóstico, prevenção e terapêutica de doenças orais, sendo também determinante na manutenção dos tratamentos efetuados nas outras áreas de Medicina Dentária, que requerem uma adequada limpeza dentária através da destartarização e profilaxia. A posição dentária e a cor do sorriso são duas valências com elevado impacto psicossocial.",
    descr:
      " Na sociedade atual, a aparência tem um peso bastante elevado, pelo que a busca de um sorriso mais branco, para além de harmonioso, é uma constante na nossa clínica!",
  },
  {
    id: 6,
    img: "assets/img/portfolio/port-5.jpg",
    title: "Cirurgia e reabilitação",
    subtitle: "Medicina Dentária",
    category: "Medicina Dentária",
    link: "cirurgia-e-reabilitação",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Reabilitação de dentes ausentes através da colocação de implantes dentários. Tratamento alternativo e mais cómodo do que as próteses removíveis, tratando-se de uma solução segura e eficaz para restabelecer a função e a estética. Redesenhar e melhorar o sorriso dos nossos pacientes.",
    descr:
      " A modificação do aspecto da zona estética, o que mostramos quando sorrisos, pode ser feito através de facetas cerâmicas ou restaurações a resina minimamente invasivas. A bichectomia é um procedimento cirúrgico que tem como objetivo reduzir o volume das bochechas, deixando o rosto mais fino e alongado. Nesse procedimento, retiram-se total ou parcialmente as bolas de Bichat, que ficam localizados entre o maxilar e a mandíbula e conferem o formato arredondado ao rosto.",
  },
  {
    id: 7,
    img: "assets/img/portfolio/port-6.jpg",
    title: "Ortodontia",
    subtitle: "Medicina Dentária",
    category: "Medicina Dentária",
    link: "ortodontia",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "A Ortodontia é a especialidade da Odontologia relacionada ao estudo, prevenção e tratamento dos problemas de crescimento, desenvolvimento e amadurecimento da face, dos arcos dentários e da oclusão, ou seja, disfunções dento-faciais. A nossa clínica oferece 2 opções: Ortodontia convencional ou invisível. A Ortodontia alinha os dentes para um sorriso visualmente mais harmonioso e bonito. Além disso, o aparelho ortodôntico, ao reposicionar os dentes corretamente, contribui para a melhor função da boca na mastigação, fala e até respiração.",
    descr:
      "O Invisalign® e o Clearcorrect permitem realizar um tratamento ortodôntico eficaz e discreto. Através de alinhadores dentários transparentes, este aparelho é quase impercetível para o exterior.",
  },
  {
    id: 8,
    img: "assets/img/portfolio/port-7.jpg",
    title: "Endodontia",
    subtitle: "Medicina Dentária",
    category: "Medicina Dentária",
    link: "endodontia",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "A Endodontia é o ramo da medicina dentária comummente designado “Desvitalização”. Estuda a patologia da polpa dentária e tecidos que rodeiam as raízes dos dentes, bem como o seu diagnóstico e tratamento. A polpa dentária é o órgão que se encontra no interior do dente (canal radicular), chamada vulgarmente de “nervo do dente”. A preservação da estrutura dentária é a máxima desta especialidade, na qual se remove o nervo do dente e se promove o selamento dos canais com materiais próprios para o efeito.",
  },
  {
    id: 9,
    img: "assets/img/portfolio/port-9.jpg",
    title: "Consulta de dermatologia",
    subtitle: "Dermatologia",
    category: "Dermatologia",
    link: "consulta-de-dermatologia",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Para consulta de avaliação são comuns as seguintes razões: acne, malformações vasculares, doenças alérgicas.",
  },
  {
    id: 10,
    img: "assets/img/portfolio/port-10.jpg",
    title: "Técnica FUE",
    subtitle: "Tratamentos Capilares",
    category: "Tratamentos Capilares",
    link: "tecnica-fue",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "A realização de um transplante capilar, utilizando a técnica FUE, é feita através de  alguns passos rápidos e indolores. Após realização de fotografias, são feitos os desenhos das zonas de tratamento e inicia-se a extração das unidades foliculares.",
  },
  {
    id: 11,
    img: "assets/img/portfolio/port-11.jpg",
    title: "Plasma rico em plaquetas",
    subtitle: "Tratamentos Capilares",
    category: "Tratamentos Capilares",
    link: "plasma-rico-em-plaquetas",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "O PRP é injetado diretamente na área de tratamento. As plaquetas contêm fatores de crescimento que regeneram o couro cabeludo o aparecimento de novos vasos que estimulam o crescimento de cabelo novo.",
  },
  {
    id: 12,
    img: "assets/img/portfolio/port-12.jpg",
    title: "Consultas de nutrição e emagrecimento",
    subtitle: "Nutrição",
    category: "Nutrição",
    link: "consultas-de-nutrição-e-emagrecimento",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Uma alimentação saudável é a base para ter uma vida com mais longevidade e bem-estar, minimizando a incidência de doenças ou problemas de saúde. Deve ser variada e equilibrada, com os nutrientes necessários para suprir as necessidades do organismo. Na sua consulta de nutrição é elaborado um plano alimentar personalizado, ajustado às suas necessidades nutricionais.",
  },
  {
    id: 13,
    img: "assets/img/portfolio/port-13.jpg",
    title: "Emagrecimento pronokal",
    subtitle: "Nutrição",
    category: "Nutrição",
    link: "consultas-de-nutrição-e-emagrecimento",
    anestesia: "none",
    resultado: "none",
    durabilidade: "none",
    description:
      "Método Pronokal consiste numa dieta para perda de peso rápido, de forma saudável, com o apoio de uma equipa de profissionais e com quem verá resultados logo nas primeiras semanas. Neste método fará uma dieta citogénica, baixa em hidratos de carbono e gordura, com uma ingestão de proteínas adequada que permitirá que perca gordura e preserve massa muscular.",
  },
];

export default portfolioData;
