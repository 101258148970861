import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import HeaderStyleFive from "./pages/HeaderStyleFive/HeaderStyleFive";
import HeaderStyleFour from "./pages/HeaderStyleFour/HeaderStyleFour";
import HeaderStyleSeven from "./pages/HeaderStyleSeven/HeaderStyleSeven";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import Portfolio from "./pages/Portfolio/Portfolio";
import PortfolioDetails from "./pages/PortfolioDetails/PortfolioDetails";
import Services from "./pages/Services/Services";
import ServicesDetails from "./pages/ServicesDetails/ServicesDetails";
import Team from "./pages/Team/Team";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/home" element={<HomeTwo />} />

          <Route path="/sobre" element={<About />} />
          <Route path="/tratamentos" element={<Portfolio />} />
          <Route path="/casos" element={<Services />} />
          <Route path="/tratamentos_:id" element={<PortfolioDetails />} />
          <Route path="/blog_:id" element={<BlogDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contactos" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
