import React from "react";
import { FaRegComment, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const BlogLeftSideBar = () => {
  return (
    <>
      <div className="col-xl-4 col-lg-4">
        <div className="blog__sidebar">
          <div className="sidebar__widget mb-75 ">
            <br></br>

            <div className="sidebar__widget-content">
              <div className="rc-post">
                <ul>
                  <li className="d-flex mb-20">
                    <div className="rc-thumb mr-15">
                      <img
                        src="assets/img/blog/rc/rc-1.jpg"
                        width="50px"
                        alt="rc-post"
                      />
                    </div>
                    <div className="rc-text">
                      <h6>Andreia Lourador</h6>
                      <div className="rc-meta" style={{ textAlign: "justify" }}>
                        <span>
                          Licenciada pela Universidade Lusíada do Porto, Andreia
                          Lourador é especialista em Psicologia Clínica e da
                          Saúde. Com formação em Hipnose e PNL, acredita
                          profundamente no Poder da Mente. Para a psicóloga quem
                          o descobre e domina, pode navegar com sucesso e mais
                          serenamente pelo fluxo do Universo, na conquista da
                          harmonia e felicidade.
                        </span>
                      </div>
                    </div>
                  </li>
                  <li className="d-flex mb-20">
                    <div className="rc-thumb mr-15">
                      <img
                        src="assets/img/blog/rc/rc-2.jpg"
                        width="50px"
                        alt="rc-post"
                      />
                    </div>
                    <div className="rc-text">
                      <h6>Joana Lino</h6>
                      <div className="rc-meta" style={{ textAlign: "justify" }}>
                        <span>
                          Mestre em Medicina Dentária e com diversas pós
                          graduações na área de Medicina Estética com prática
                          exclusiva. É pioneira em Medicina Estética e
                          Rejuvenescimento em Portugal e conta com mais de 30
                          cursos internacionais e 5 anos de experiência na área
                          de Medicina Estética.
                        </span>{" "}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogLeftSideBar;
