import React from "react";
import CommonCtaArea from "../../components/CommonCtaArea/CommonCtaArea";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeCapabilities from "../Home/HomeCapabilities/HomeCapabilities";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";

const Services = () => {
  return (
    <>
      <PageHelmet pageTitle="Casos Clínicos" />

      <HomeOneHeader />
      <CommonPageHeader
        title="Casos clínicos"
        subtitle="O processo e os resultados"
        image="url(assets/img/page-title/page-title-5.jpg)"
      />
      <HomeCapabilities />
      <CommonCtaArea />
      <Footer />
    </>
  );
};

export default Services;
