import Slider from "react-slick";
import SingleBrandItem from "../../../components/SingleBrandItem/SingleBrandItem";

const HomeBrands = () => {
  // slick setting
  const settings = {
    autoplay: false,
    autoplaySpeed: 10000,
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section
        className="brand__area p-relative pb-50"
        style={{ marginTop: "-150px" }}
      >
        <div className="container p-relative">
          <div className="row">
            <div className="col-xl-6 col-lg-7">
              <div
                className="section__title section__title-3 wow fadeInUp"
                data-wow-delay=".2s"
                style={{ marginTop: "100px" }}
              >
                <h2>Notícias e artigos publicados em:</h2>
              </div>
              <div
                className="brand__subtitle mb-90 wow fadeInUp"
                data-wow-delay=".4s"
              ></div>
            </div>
          </div>
          <div className="row ">
            <div className="col">
              <SingleBrandItem img_1="1" img_2="7" />
            </div>
            <div className="col">
              <SingleBrandItem img_1="2" img_2="4" />
            </div>
            <div className="col">
              <SingleBrandItem img_1="6" img_2="3" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBrands;
