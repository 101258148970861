import { CgArrowLongRight } from "react-icons/cg";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomeAbout = () => {
  return (
    <>
      <section className="about__area pb-200 pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 pr-0 col-lg-6">
              <div className="m-img">
                <img src="assets/img/about/about-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
              <div className="about__content">
                <div className="section__title mb-25">
                  <span>A origem</span>
                  <h2>Dra. Joana Lino</h2>
                </div>
                <p>
                  Mestre em Medicina Dentária e com diversas pós graduações na
                  área de Medicina Estética com prática exclusiva.
                </p>
                <div className="about__list">
                  <ul>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Mais de 30 cursos internacionais
                      </span>
                    </li>
                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        5 anos de experiência na área de Medicina Estética
                      </span>
                    </li>

                    <li>
                      <span>
                        <i>
                          {" "}
                          <FaCheck />{" "}
                        </i>
                        Pioneira em Medicina Estética e Rejuvenescimento em
                        Portugal
                      </span>
                    </li>
                  </ul>
                </div>
                <Link to="/blogs" className="z-btn ">
                  Artigos
                  <i>
                    {" "}
                    <CgArrowLongRight />{" "}
                  </i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
