import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = ({ pageTitle }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> PROAGE || {pageTitle} </title>
        <meta
          name="description"
          content="PROAGE Clinic by Joana Lino | Positive Aging. Rua De Gondarém, 782, Fr. E, Foz do Douro, Porto"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="keywords"
          content="proage, proage clinic, medicina estética, botox, ácido hialurónico, harmonização facial, muradlino, botox porto, porto, clínica medicina estética, Joana Lino"
        />
      </Helmet>
    </>
  );
};

export default PageHelmet;
