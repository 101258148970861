const featuredWorksData = [
  {
    id: 1,
    img: "assets/img/case/case-1.jpg",
    title: "Business Growth",
    subtitle: "Consulting",
    category: "coaching",
  },
  {
    id: 2,
    img: "assets/img/case/case-2.jpg",
    title: "Business Growth",
    subtitle: "Consulting",
    category: "facilitation",
  },
  {
    id: 3,
    img: "assets/img/case/case-3.jpg",
    title: "Leadership & Change",
    subtitle: "Strategy",
    category: "coaching",
  },
  {
    id: 4,
    img: "assets/img/case/case-4.jpg",
    title: "Human Resources",
    subtitle: "Coaching",
    category: "consulting",
  },
  {
    id: 5,
    img: "assets/img/case/case-5.jpg",
    title: "Digitalisation & IT",
    subtitle: "IT Solution",
    category: "consulting",
  },
  {
    id: 6,
    img: "assets/img/case/case-6.jpg",
    title: "Data Analytics",
    subtitle: "Analytics",
    category: "consulting",
  },
  {
    id: 7,
    img: "assets/img/case/case-7.jpg",
    title: "Virtual Facilitation",
    subtitle: "Coaching",
    category: "facilitation",
  },
  {
    id: 8,
    img: "assets/img/case/case-1.jpg",
    title: "Business Growth",
    subtitle: "Consulting",
    category: "consulting",
  },
  {
    id: 9,
    img: "assets/img/case/case-3.jpg",
    title: "Leadership & Change",
    subtitle: "Strategy",
    category: "consulting",
  },
  {
    id: 10,
    img: "assets/img/case/case-3.jpg",
    title: "Leadership & Change",
    subtitle: "Strategy",
    category: "strategy",
  },
  {
    id: 11,
    img: "assets/img/case/case-4.jpg",
    title: "Human Resources",
    subtitle: "Coaching",
    category: "strategy",
  },
  {
    id: 12,
    img: "assets/img/case/case-7.jpg",
    title: "Virtual Facilitation",
    subtitle: "Coaching",
    category: "strategy",
  },
];

export default featuredWorksData;
