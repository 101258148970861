import React from "react";
import HomeTwoSingleSlide from "../../../components/HomeTwoSingleSlide/HomeTwoSingleSlide";
import Slider from "react-slick";

const HomeTwoHeroSlider = () => {
  // slider setting
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    speed: 500,
    infinite: true,
    dots: false,
    fade: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <section className="slider__area slider__area-2">
        <Slider className="slider-active" {...settings}>
          <HomeTwoSingleSlide
            slider_class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2"
            content_class="slider__content-3 text-center"
            attr_2={
              <h1>
                <br></br>
                <br></br>
              </h1>
            }
            btn_text="Agende uma consulta"
          />
        </Slider>
      </section>
    </>
  );
};

export default HomeTwoHeroSlider;
