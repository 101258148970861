import React from "react";
import { Link } from "react-router-dom";
import { CgArrowLongRight } from "react-icons/cg";

const HomeTwoAchievement = () => {
  return (
    <>
      <section className="achievement__area pt-135 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="achievement__content">
                <div className="section__title section__title-3 mb-20">
                  <span>A Nossa Missão</span>
                  <h2>Envelhecer é inevitável,</h2>
                  <h3>
                    mas podemos encarar este processo natural da vida de uma
                    forma positiva.
                  </h3>
                </div>
                <p style={{ textAlign: "justify" }}>
                  A idade é apenas um número. Envelhecer graciosamente é ter
                  propósito. Envelhecer graciosamente significa tratarmo-nos bem
                  mental e fisicamente, ter uma abordagem positiva em relação ao
                  processo de envelhecimento. Viver uma vida cheia de propósito
                  é o que nos mantém jovens. É com base neste conceito{" "}
                  <strong style={{ fontStyle: "italic" }}>
                    "Positive Aging"
                  </strong>{" "}
                  que a PROAGE Clinic se move, abraçando a fase da vida em que
                  nos encontramos, sendo esta sempre a nossa melhor versão.
                </p>

                <Link to="/sobre" className="z-btn">
                  Sobre Nós
                  <i>
                    <CgArrowLongRight />
                  </i>
                </Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="row">
                <div className="col ">
                  <div className="achievement__thumb m-img pl-10 text-right">
                    <img
                      src="assets/img/achievement/IMG_2290.jpg"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>
                <br></br>
                <div className="col d-md-none d-lg-none d-xl-block">
                  <div className="achievement__thumb m-img pl-10 text-right ">
                    <img
                      src="assets/img/achievement/IMG_2374.jpg"
                      alt="Joana Lino"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTwoAchievement;
