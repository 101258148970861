import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/shared/Footer";
import PageHelmet from "../../components/shared/PageHelmet";
import HomeOneHeader from "../Home/HomeOneHeader/HomeOneHeader";
import PortfolioDetailsArea from "./PortfolioDetailsArea/PortfolioDetailsArea";
import data from "../../data/portfolioData";
import { useLocation } from "react-router-dom";

const PortfolioDetails = (props) => {
  const location = useLocation();
  const state = location.state;
  return (
    <>
      <PageHelmet pageTitle="Tratamentos" />

      <HomeOneHeader />
      <CommonPageHeader title={state.title} subtitle="Tratamentos" />
      <PortfolioDetailsArea data={data} />
      <Footer />
    </>
  );
};

export default PortfolioDetails;
